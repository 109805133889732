import {
  useState,
  createContext,
  useEffect,
  ReactNode,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { extractedTailwindTheme } from '@app/lib/helpers';
import { inRange } from 'lodash';

type ThemeProviderContextType = {
  theme: themeType,
  handleThemeChange: (theme: themeType) => void;
  isMobile: boolean;
  isTablet: boolean;
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  isPartnerPromptOpen: boolean;
  setIsPartnerPromptOpen: Dispatch<SetStateAction<boolean>>;
  showLoginDropdown: boolean;
  setShowLoginDropdown: Dispatch<SetStateAction<boolean>>;
}

export const ThemeContext = createContext<ThemeProviderContextType | any>({});

const variants = ['light', 'dark'];
export type themeType = 'light' | 'dark';

export interface ThemeProviderProps {
  initialTheme?: themeType,
  children: ReactNode,
}

export const ThemeProvider = ({ initialTheme, children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState(initialTheme);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isPartnerPromptOpen, setIsPartnerPromptOpen] = useState<boolean>(false);
  const [showLoginDropdown, setShowLoginDropdown] = useState<boolean>(false);

  const handleThemeChange = (newTheme: themeType) => {
    window.localStorage.setItem('theme', newTheme);

    const root = window.document.documentElement;
    const isDark = newTheme === 'dark';

    root.classList.remove(isDark ? 'light' : 'dark');
    root.classList.add(newTheme);

    setTheme(newTheme);
  };

  const setScreenType = () => {
    const screensize = window.innerWidth;
    const tabletBreakpoint = +extractedTailwindTheme.screens.md.replace(
      'px',
      '',
    );
    const desktopBreakpoint = +extractedTailwindTheme.screens.lg.replace(
      'px',
      '',
    );
    setIsMobile(screensize < tabletBreakpoint);
    setIsTablet(inRange(screensize, desktopBreakpoint, tabletBreakpoint));
  };

  /**
   * Analyze on fist mount
   */
  // useEffect(() => {
  //   const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  //   const localTheme = window.localStorage.getItem('theme');

  //   if (variants.includes(localTheme)) {
  //     handleThemeChange(localTheme as themeType);
  //   } else if (isDarkMode) {
  //     handleThemeChange('dark');
  //   } else {
  //     handleThemeChange(initialTheme);
  //   }
  //   // useEffect only renders on client side, window assured to be not undefined
  //   setScreenType();
  // }, []);

  // TODO: Temporary only since dark mode is not supported yet
  useEffect(() => {
    handleThemeChange('light')

    setScreenType();
  }, [])

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', () => {
      setScreenType();
    });
  }

  const value: ThemeProviderContextType = {
    theme,
    handleThemeChange,
    isMobile,
    isTablet,
    isSidebarOpen,
    setIsSidebarOpen,
    isPartnerPromptOpen,
    setIsPartnerPromptOpen,
    showLoginDropdown,
    setShowLoginDropdown
  }

  return (
    <ThemeContext.Provider
      value={value}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const useThemeContext = () => useContext<ThemeProviderContextType>(ThemeContext);

export default ThemeProvider;

export { useThemeContext };

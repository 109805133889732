import Image from "next/image";
import { useEffect, useState } from "react";
import { mdiClose, mdiMinus, mdiSend } from '@mdi/js';
import Icon from "@mdi/react";
import { APP_URL, Z_INDECES } from "@app/constants/default";
import { Field, Form } from "react-final-form";
import { backendApi } from "@app/lib/api";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import {
    PARTNERS_GPO_PAGE, PARTNERS_NCA_PAGE, PARTNERS_REFERRAL_PAGE, PARTNERS_COUNTRY_CLUB_PAGE,
    PARTNERS_DEDICATED_OFFICE, PARTNERS_HOME_TEAM_RESTAURANT_GROUP, PARTNERS_DAIRY_QUEEN_CORPORATE,
    PARTNERS_ROSATIS_PIZZA,
} from "@app/constants/routes";

const PartnersPortal = () => {
    const router = useRouter()
    const isAtPartnersPage = [PARTNERS_COUNTRY_CLUB_PAGE, PARTNERS_REFERRAL_PAGE, PARTNERS_GPO_PAGE, PARTNERS_NCA_PAGE, PARTNERS_DEDICATED_OFFICE, PARTNERS_HOME_TEAM_RESTAURANT_GROUP, PARTNERS_DAIRY_QUEEN_CORPORATE, PARTNERS_ROSATIS_PIZZA].some(
        (page) => router.pathname.includes(page)
    );

    const PARTNERS_PORTAL_KEY = 'simplewaste-web:partners-portal';

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenPlaceholder, setIsOpenPlaceHolder] = useState(false);

    const handleOpen = () => {
        setIsOpenPlaceHolder(false);
        setIsOpen(true);
    }

    useEffect(() => {
        const hasSession = sessionStorage.getItem(PARTNERS_PORTAL_KEY);

        if (hasSession) {
            return setIsOpenPlaceHolder(false);
        }

        sessionStorage.setItem(PARTNERS_PORTAL_KEY, 'viewed');
        return setIsOpenPlaceHolder(true);
    }, [])


    const handleOnSubmit = async ({ affiliateCode }, form) => {
        try {
            const originUrl = typeof window !== 'undefined' ? location.origin : APP_URL;
            const { data: { data } } = await backendApi.post(`/web/affiliate`, {
                affiliateCode,
                callbackUrl: originUrl,
            })

            router.push(data)
            setIsOpen(false)
        } catch (error) {
            toast.warn('Invalid Affiliate Code!');
        }

        form.reset()
    }

    const openedVersion = (
        <div style={{ zIndex: Z_INDECES.MODAL }} className="fixed bottom-0 right-0 max-w-md bg-background-primary-dark-dark text-white rounded-lg m-5 shadow-lg">
            <div className="p-5">
                <div className="text-right">
                    <button type="button" onClick={() => setIsOpen(false)}>
                        <Icon path={mdiMinus} size={1} />
                    </button>
                </div>
                <div className="mb-5">
                    <Image src={"/assets/icons/mdi-handshake-outline.png"} width="50" height="50" alt="SimpleWaste" />
                    <h4>Affiliate Access Point</h4>
                </div>
                <p className="mb-3">Are you part of an affiliated GPO, Buying Group, or Association?</p>
                <p className="mb-2">Enter your code below.</p>
            </div>
            <div className="bg-background-disabled-dark p-5 rounded-lg">
                <Form
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="flex items-center space-x-3">
                            <Field name="affiliateCode">
                                {({ input }) => (
                                    <input placeholder="Enter Code Here" {...input} className="w-full p-2 px-3 rounded-md bg-background-main-dark" required />
                                )}
                            </Field>
                            <button type="submit" className="bg-secondary p-2 rounded-md">
                                <Icon path={mdiSend} size={1} />
                            </button>
                        </form>
                    )}
                />
            </div>
        </div>
    )

    // TODO: Handle moving
    const renderHoverText = (
        <div className="bg-background-primary-dark-dark p-5 text-white max-w-xs rounded-lg mr-5 shadow-lg">
            <div className="flex justify-between items-center mb-2">
                <h6 className="text-secondary font-bold">Enter here!</h6>
                <button type="button" onClick={() => setIsOpenPlaceHolder(false)}>
                    <Icon path={mdiClose} size={0.75} />
                </button>
            </div>
            <p className="text-xs">Welcome to the affiliate access point. Please enter your group code below.</p>
        </div>
    )

    const closedVersion = (
        <div style={{ zIndex: Z_INDECES.PARTNER_PORTAL }} className="fixed bottom-0 right-0 m-5 flex items-end">
            {isOpenPlaceholder && renderHoverText}
            <div>
                <button
                    onClick={handleOpen} className="rounded-full bg-background-stroke p-3 flex items-center justify-center w-16 h-16 sm:w-auto sm:h-auto"
                    onMouseEnter={() => setIsOpenPlaceHolder(true)}
                >
                    <Image src={"/assets/icons/mdi-handshake.png"} width="50" height="50" alt="SimpleWaste" />
                </button>
            </div>
        </div>
    )

    return isAtPartnersPage ? null : isOpen ? openedVersion : closedVersion
}

export default PartnersPortal;

/**
 * This are all the available routes supported by the project. This
 * should match to the contents of "pages" directory.
 *
 * Refer to: https://nextjs.org/docs/basic-features/pages
 *
 * NOTE: Additional pages should be suffixed with "_PAGE"
 */
 export const HOME_PAGE = '/';
 export const PLATFORM_PAGE = '/platform';
 export const INDUSTRIES_PAGE = '/industries';
 export const HAULERS_PAGE = '/haulers';
 export const ACQUISITIONS_PAGE = '/acquisitions';
 export const PARTNERS_PAGE = '/partners';
 export const PARTNERS_ROSATIS_PIZZA = '/partners/rosatis-pizza'
 export const PARTNERS_DAIRY_QUEEN_CORPORATE = '/partners/dairy-queen-corporate'
 export const PARTNERS_HOME_TEAM_RESTAURANT_GROUP = '/partners/home-team-restaurant-group'
 export const PARTNERS_DEDICATED_OFFICE = '/partners/dedicated-office'
 export const PARTNERS_COUNTRY_CLUB_PAGE = '/partners/country-club'
 export const PARTNERS_REFERRAL_PAGE = '/partners/referral';
 export const PARTNERS_GPO_PAGE = '/partners/gpopros';
 export const PARTNERS_NCA_PAGE = '/partners/nca';
 export const ABOUT_PAGE = '/about';
 export const CAREERS_PAGE = '/careers';
 export const OFFLINE_PAGE = '/_offline';
 export const ERROR_PAGE = '/500';
 export const MISSING_PAGE = '/404';
 export const CONTACT_PAGE = '/contact-us'
 export const TERMS_PAGE = '/terms';
 export const PRIVACY_POLICY_PAGE = '/privacy-policy';

import { useMemo, ButtonHTMLAttributes, ReactNode } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  buttonType?:
  | 'primary'
  | 'secondary'
  | 'secondary-light'
  | 'gradient'
  | 'base'
  full?: boolean;
  startIcon?: ReactNode;
  trailingIcon?: ReactNode;
  size?:
  | 'xs'
  | 'sm'
  | 'base'
  | 'l'
  | 'xl'
  | '2xl'
  | '3xl';
}

const Button = ({
  buttonType = 'primary',
  size = 'base',
  className = '',
  full,
  children,
  startIcon,
  trailingIcon,
  ...otherProps
}: ButtonProps) => {
  const buttonBgColor = useMemo(() => {
    switch (buttonType) {
      case 'secondary':
        return `bg-base text-secondary hover:bg-secondary-low-em`;
      case 'gradient':
        return 'bg-gradient-to-l from-[#22C55E] to-[#06B6D4] text-light hover:from-[#86EFAC] hover:to-[#67E8F9] border border-transparent hover:border-white'
      case 'secondary-light':
        return 'bg-secondary-low-em text-text-color-mid-em hover:bg-green-100 hover:text-secondary border border-transparent hover:border-secondary'
      case "base":
          return "text-text-color-high-em bg-white hover:bg-background-mid-em";
      case 'primary':
      default:
        return 'bg-secondary text-white hover:bg-secondary-dark';
    }
  }, [buttonType]);

  const buttonBorder = useMemo(() => {
    switch (buttonType) {
      case 'secondary': return `border border-secondary disabled:border-transparent`;
      case 'base': return 'border border-text-color-disabled';
      case 'primary':
      default: return 'border-transparent';
    }
  }, [buttonType]);

  const buttonFont = useMemo(() => {
    switch (size) {
      case 'xs': return 'btn-text-xs';
      case 'sm': return 'btn-text-sm';
      case 'base': return 'btn-text-base';
      case 'l':
      case 'xl': return 'btn-text-lg';
      default: return 'btn-text-base';
    }
  }, [size]);

  const buttonPadding = useMemo(() => {
    switch (size) {
      case 'xs':
      case 'sm': return 'px-3 py-2';
      case 'base':
      case 'l': return 'px-4 py-2';
      case 'xl': return 'px-6 py-3';
      case '2xl': return 'px-6 py-4';
      case '3xl': return 'px-7 py-4';
      default: return 'px-4 py-2';
    }
  }, [size]);

  const buttonRadius = useMemo(() => {
    switch (size) {
      case 'xs':
      case '3xl':
      case 'sm': return 'rounded-lg';
      case 'base':
      case 'l':
      case 'xl':
      default: return 'rounded-xl';
    }
  }, [size]);

  const isFull = full && 'w-full';
  const defaultClassStyles = `${buttonRadius} shadow-sm disabled:cursor-not-allowed
   focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-secondary
   disabled:text-text-color-disabled disabled:shadow-none flex items-center justify-center
   disabled:bg-background-mid-em`;
  const renderStartIcon = startIcon == null ? null : (<span className="mr-2">{startIcon}</span>);
  const renderTrailingIcon = trailingIcon == null ? null : (<span className="ml-2">{trailingIcon}</span>);

  return (
    <button
      type="button"
      className={
        `${className} ${defaultClassStyles} ${buttonBgColor} ${buttonFont} ${buttonPadding} ${buttonBorder} ${isFull}`
      }
      {...otherProps}
      disabled={otherProps.disabled}
    >
      {renderStartIcon}
      {children}
      {renderTrailingIcon}
    </button>
  );
};

export default Button;

import { Fragment, ReactNode, useState } from "react"
import Button from "./button"
import ReactModal from 'react-modal'
import { ELEMENT_MODAL_ID, Z_INDECES } from "@app/constants/default";
import { InlineWidget } from "react-calendly";
import Image from "next/image";
import { useThemeContext } from "@app/contexts/theme-context";
import Icon from "@mdi/react";
import { mdiClock } from "@mdi/js";


export const Calendly = ({ handleModalClose }) => {
    const { isMobile } = useThemeContext();

    return (<div>
        <InlineWidget url={process.env.NEXT_PUBLIC_CALENDLY_EMBED_URL} styles={{
            height: !isMobile ? '750px' : '630px',
        }} />
        <div className="flex items-center justify-center w-full mt-5 sm:mt-auto">
            <Button onClick={handleModalClose} buttonType="base" className="px-12">
                Close
            </Button>
        </div>
    </div>
    );
};



ReactModal.setAppElement(ELEMENT_MODAL_ID);

export interface ScheduleDemoProps {
    children: (setIsModalOpen) => ReactNode,
    skipIntro?: boolean,
}

const ScheduleDemo = ({ children, skipIntro = false }: ScheduleDemoProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isIntro, setIsIntro] = useState(true && !skipIntro);
    const { isTablet, isMobile } = useThemeContext();

    const isTabletOrMobile = isTablet || isMobile;
    const modalWidthTabletMobile = isTablet ? '50vw' : '95vw';
    const modalWidth = isTabletOrMobile ? modalWidthTabletMobile : isIntro ? '25vw' : '55vw'

    const defaultModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '0.375rem',
            minWidth: modalWidth,
            maxWidth: modalWidth,
            minHeight: isIntro ? '30vh' : '60vh',
            //   height: '90vh',
            overlfow: 'scroll',
            zindex: Z_INDECES.MODAL,
            background: isIntro ? 'white' : 'none',
            border: isIntro ? '1px solid rgb(204, 204, 204)' : 'none'
        },
        overlay: {
            zIndex: Z_INDECES.MODAL_OVERLAY,
            background: 'rgba(52,64,84,0.7)',
            ['--tw-backdrop-blur']: 'blur(12px)',
            backdropFilter: 'var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)'
        }
    };

    const handleModalClose = () => {
        setIsIntro(true);
        setIsModalOpen(false);
    }

    const renderIntro = (
        <div className="text-center">
            <div className="mb-5">
                <div className="mb-3">
                    <Image src="/assets/svgs/global/logo-mark.svg" width="160" height="30" alt="SimpleWaste Logo" />
                </div>
                <div id="schedule__demo" className="mb-3 rounded-lg">
                    <Image src="/images/schedule-demo-phone.png" alt="SimpleWaste Mobile" height={200} width={200} />
                </div>
                <div className="mb-3">
                    <h4 className="mb-1">SimpleWaste Platform Demo</h4>
                    <div className="flex justify-center">
                        <div className="flex items-center justify-center bg-green-100 rounded-xl text-secondary text-xs py-2 space-x-2 px-3">
                            <Icon path={mdiClock} size={0.8} />
                            <div>30 mins</div>
                        </div>
                    </div>
                </div>
                <p className="text-sm mb-5">
                    We look forward to discussing how we can meet your waste management needs.
                </p>
            </div>
            <div className="flex items-center space-x-3">
                <Button full buttonType="secondary" onClick={handleModalClose}>
                    Cancel
                </Button>
                <Button full onClick={(e) => {
                  e.stopPropagation()
                  setIsIntro(false)
                }}>
                    Proceed
                </Button>
            </div>
        </div>
    )

    return (
        <Fragment>
            <ReactModal
                isOpen={isModalOpen} contentLabel="Schedule Demo" style={defaultModalStyles}
                shouldCloseOnOverlayClick shouldCloseOnEsc onRequestClose={handleModalClose}
            >
                {isIntro ? renderIntro : <Calendly handleModalClose={handleModalClose} />}
            </ReactModal>
            {() => {

            }}
            {children(setIsModalOpen)}
        </Fragment>
    )
}

export default ScheduleDemo

import { ReactNode } from "react";
import { NAV_HEIGHT } from "@app/constants/default";
import Footer from "./footer";
import Navbar from "./navbar";
import { useThemeContext } from "@app/contexts/theme-context";

export interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const { setIsSidebarOpen, setIsPartnerPromptOpen, setShowLoginDropdown } = useThemeContext();
  return (
    <div onClick={() => {
      setIsSidebarOpen(false)
      setIsPartnerPromptOpen(false)
      setShowLoginDropdown(false)
    }}>
      <Navbar />
      <div style={{ paddingTop: NAV_HEIGHT }}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default AppLayout;

import Link from "next/link";
import Icon from "@mdi/react";
import { mdiAccountOutline, mdiHandshake, mdiTruckOutline } from "@mdi/js";

const LoginDropdown = () => {
  const loginOptions = [
    {
      label: "Client Login",
      mdiIcon: mdiAccountOutline,
      path: process.env.NEXT_PUBLIC_CLIENT_URL,
      newTab: true,
    },
    {
      label: "Partner Login",
      mdiIcon: mdiHandshake,
      path: "/login",
      newTab: false,
    },
    {
      label: "Hauler Login",
      mdiIcon: mdiTruckOutline,
      path: process.env.NEXT_PUBLIC_BIDDING_URL,
      newTab: true,
    },
  ];

  return (
    <div
      role="menu"
      className="absolute bottom-32 right-3 xl:top-16 xl:bottom-auto xl:right-20  
        grid gap-y-5 bg-base w-[206px] xl:w-[185px] py-4 px-5 
        text-text-color-high-em text-body-md shadow-lg rounded"
      aria-orientation="vertical"
    >
      {loginOptions.map((o) => (
        <Link href={o.path} key={o.mdiIcon} passHref >
          <a target={o.newTab ? "_blank" : ""} rel="noopener noreferrer">
            <span className="grid grid-cols-5 items-center gap-x-4 whitespace-nowrap cursor-pointer">
              <Icon className="col-span-1 text-primary-dark" size={1} path={o.mdiIcon} />
              <span>{o.label}</span>
            </span>
          </a>
        </Link>
      ))}
    </div>
  );
};

export default LoginDropdown;

// Configurations
export const ELEMENT_ROOT_ID = '#__next';
export const ELEMENT_MODAL_ID = '#modals'
export const ACCESS_TOKEN_KEY = 'simplewaste-web:access_token';
export const FB_APP_ID = '685801445945216'
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL;

export const EXTERNAL_API = process.env.NEXT_PUBLIC_EXTERNAL_API || 'https://simplewaste-api-dev.herokuapp.com';

// HTML document defaults
export const DEFAULT_META_TITLE = 'SimpleWaste - Waste made easy.';
export const DEFAULT_META_DESCRIPTION = `SimpleWaste is a technology driven company using cloud based platforms to effectively and efficiently service thousands of locations nationwide.`;

// Common default values
export const DEFAULT_LIMIT = 20;
export const DEFAULT_PAGE = 1;

// react-table pagination defaults
export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_PAGE_SIZES = [5, 10, 20] as const;
export const DEFAULT_PAGE_SIZE: typeof DEFAULT_PAGE_SIZES[number] = 5;


// Dimentions 
export const NAV_HEIGHT = 76;

// Z-INDECES
export const Z_INDECES = {
  NAVBAR: 40,
  FLOATING_COMPONENT: 30,
  PARTNER_PORTAL: 35,
  MODAL_OVERLAY: 49,
  MODAL: 50,
};
  
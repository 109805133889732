import { ACCESS_TOKEN_KEY, EXTERNAL_API } from '@app/constants/default';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { capitalize, isArray } from 'lodash';
import { getSession } from 'next-auth/react';
import { toast } from 'react-toastify';

const axiosConfig: AxiosRequestConfig = {
  baseURL: `${EXTERNAL_API}/api`,
  responseType: 'json',
};

export const frontendApi = axios.create(axiosConfig);

export const backendApi = axios.create(axiosConfig);

export type AxiosRequestConfigExtendedType = AxiosRequestConfig & { _retry?: boolean }

frontendApi.interceptors.response.use(null, async (err: AxiosError) => {
  if (err.response) {
    const { status, data, statusText } = err.response || {};
    const message = data ? data.message : statusText;
    const originalRequest: AxiosRequestConfigExtendedType = err.config;

    if (status >= 300 && status < 400) {
      toast.warning(message || 'Endpoint not found');
    }

    if (status >= 400 && status < 500) {
      // Catch unauth errors. 401 is here due to response of /auth/me with 401
      if (status === 403 || status === 401) {
        /**
         *
         * Hackish approach to trigger a manual refresh of token
         *
         * TODO: Could be removed when next-auth has an optional to manually refresh
         * https://github.com/nextauthjs/next-auth/issues/596#issuecomment-943453568
         */

        // eslint-disable-next-line no-underscore-dangle
        if (!originalRequest._retry) {
          try {
            // eslint-disable-next-line no-underscore-dangle
            originalRequest._retry = true;
            const session = await getSession();

            const { data: res } = await backendApi.get('/auth/refresh', {
              params: {
                token: session.refreshToken,
              },
            });

            originalRequest.headers.Authorization = `Bearer ${res.access_token}`;
            return frontendApi(originalRequest);
          // eslint-disable-next-line no-console
          } catch (error) { console.warn(error); }
        }

        // to limit toast error of unauth
        toast.warning('Unauthorized Access', { toastId: 'unauth' });

        // return handleSignOut();
      }

      /** Catch class-validator error format */
      if (isArray(message)) {
        message.map((m) => toast.warning(m ? capitalize(m) : 'Something went wrong'));
      }

      // default warning of 4xx
      toast.warning(message || 'Something went wrong');
    } else {
      // 5xx
      toast.error(message || 'Something went wrong');
    }
  } else {
    // This is usually misconfigured endpoint
    toast.error('Connection error. Please check your internet connection', { toastId: 'connerr' });
  }

  return Promise.reject(err);
});

backendApi.interceptors.response.use(null, async (err: AxiosError) => Promise.reject(err));

frontendApi.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export const fetcher = (url) => frontendApi.get(url).then((res) => res.data);

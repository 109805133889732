import Image from 'next/image';
import { mdiOfficeBuildingMarkerOutline, mdiEmailFastOutline } from '@mdi/js';
import Icon from '@mdi/react';
import Link from 'next/link';
import {
  ACQUISITIONS_PAGE,
  HAULERS_PAGE,
  INDUSTRIES_PAGE,
  PARTNERS_PAGE,
  PLATFORM_PAGE,
  CONTACT_PAGE,
  TERMS_PAGE,
  PRIVACY_POLICY_PAGE
} from "@app/constants/routes";
import { useRouter } from 'next/router';

const FooterDetails = ({ icon, label, children }) => (
  <div className="flex flex-col space-y-3">
    <div className="flex flex-row space-x-1.5 -ml-1 text-light">
      <Icon path={icon}
        size={1}
      />
      <span className='label-lg '>
        {label}
      </span>
    </div>
    <div className="">
      {children}
    </div>
  </div>
);

const footerNav = [
  { title: 'Platform', url: PLATFORM_PAGE },
  { title: 'Industries', url: INDUSTRIES_PAGE },
  { title: 'Haulers', url: HAULERS_PAGE },
  { title: 'Acquisitions', url: ACQUISITIONS_PAGE },
  { title: 'Partners', url: PARTNERS_PAGE },
  { title: 'Contact Us', url: CONTACT_PAGE },
]

const Footer = () => {
  const { pathname } = useRouter()

  return (
    <footer className="flex flex-col">
      <div
        className={`${pathname === '/industries' && 'pt-28 lg:pt-36 xl:pt-44'}
       bg-background-low-em-dark pl-6 sm:px-16 xl:px-32 py-16`}
      >
        <div className="flex flex-col space-y-6 sm:space-y-0 lg:space-y-0 sm:grid sm:grid-cols-2 lg:grid lg:grid-cols-3">
          <div className="flex flex-col space-y-2">
            <div className="flex items-center flex-row space-x-1">
              <Image src='/assets/icons/icon-192.png' alt='logo' height={40} width={40} />
              <div className="flex flex-row text-light">
                <span className="text-lg">
                  SIMPLE
                </span>
                <span className="text-lg font-bold ">
                  WASTE
                </span>
              </div>
            </div>
            <span className="text-gray-200">
              Waste made easy.
            </span>
          </div>
          <div className="flex sm:hidden lg:flex flex-col sm:pb-10 lg:pb-0 space-y-5 pt-1 btn-text-lg text-secondary-light">
            {footerNav.map(({ title, url }) => (
              <Link key={title} href={url} passHref>
                <span key={title} className="cursor-pointer">
                  {title}
                </span>
              </Link>
            ))}
          </div>
          <div className="flex sm:hidden lg:flex lg:-ml-12 xl:-ml-0  flex-col space-y-5">
            {/* <FooterDetails icon={mdiOfficeBuildingMarkerOutline} label="Headquarters">
              <span className='text-base  text-gray-200'>
                El Paso, TX
              </span>
            </FooterDetails> */}
            <FooterDetails
              icon={mdiEmailFastOutline}
              label="Mail"
            >
              <span className='text-base text-gray-200'>
                contact@simplewaste.com
              </span>
            </FooterDetails>
          </div>
          <div className="hidden sm:flex lg:hidden pb-10 flex-col space-y-5">
            {/* <FooterDetails icon={mdiOfficeBuildingMarkerOutline} label="Headquarters">
              <span className='text-base  text-gray-200'>
                El Paso, TX
              </span>
            </FooterDetails> */}
            <FooterDetails
              icon={mdiEmailFastOutline}
              label="Mail"
            >
              <span className='text-base text-gray-200'>
                contact@simplewaste.com
              </span>
            </FooterDetails>
          </div>
          <div className="hidden sm:flex lg:hidden flex-col lg:pb-0 space-y-5 pt-1 btn-text-lg text-secondary-light">
            {footerNav.map(({ title, url }) => (
              <Link key={title} href={url} passHref>
                <span key={title} className="cursor-pointer">
                  {title}
                </span>
              </Link>
            ))}
          </div>
          <div className="flex flex-col space-y-5">
            {/* <FooterDetails icon={mdiOfficeBuildingMarkerOutline} label="Office Locations">
              <div className="flex flex-col text-base space-y-2">
                <span className='text-gray-200'>
                  Greater NY Metropolitan Area
                </span>
                <span className='text-gray-200'>
                  Ft. Myers, FL
                </span>
                <span className='text-gray-200'>
                  Tulsa, OK
                </span>
                <span className='text-gray-200'>
                  Plano, TX
                </span>
              </div>
            </FooterDetails> */}
            {/* <FooterDetails icon={mdiOfficeBuildingMarkerOutline} label="Headquarters">
              <div className="flex flex-col text-base space-y-2 ">
                <span className='text-gray-200'>
                  Vineland, NJ
                </span>
                <span className='text-gray-200'>
                  Ft. Lauderdale, FL
                </span>
              </div>
            </FooterDetails> */}
          </div>
        </div>
      </div>
      <div className="px-6 sm:px-9 md:px-20 lg:px-32 py-5 bg-background-main-dark">
        <div className='flex flex-col sm:flex-row space-y-3 
        sm:space-y-0 sm:space-x-5 md:space-x-0 md:justify-between'>
          <span className="text-gray-400">
            © 2022 SimpleWaste. All rights reserved.
          </span>
          <div className="flex flex-col sm:flex-row space-x-0 space-y-3
           sm:space-y-0 sm:space-x-4 text-light">
            <Link href={PRIVACY_POLICY_PAGE}>
              Privacy Policy
            </Link>
            <Link href={TERMS_PAGE}>
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;

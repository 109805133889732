import { FunctionComponent, ReactNode, useMemo, ButtonHTMLAttributes } from "react";

interface Prop extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactNode;
  buttonType?: "primary" | "secondary" | "base";
  size?: "sm" | "base";
};

const IconButton: FunctionComponent<Prop> = ({
  icon,
  buttonType = "primary",
  size = "base",
  ...otherProps
}) => {
  const buttonBgColor = useMemo(() => {
    switch (buttonType) {
      case "base":
        return "text-gray-500 border-background-stroke bg-white border";
      case "secondary":
        return `bg-white text-text-color-high-em hover:bg-background-disabled`;
      case "primary":
      default:
        return "bg-secondary hover:bg-secondary-dark disabled:opacity-50 text-white";
    }
  }, [buttonType]);

  const buttonSize = useMemo(() => {
    switch (size) {
      case "sm":
        return "w-8 h-8";
      case "base":
      default:
        return "w-10 h-10";
    }
  }, [size]);

  const className = `${buttonBgColor} ${buttonSize} rounded-full flex justify-center items-center`;
  return (
    <button type="button" {...otherProps} className={className}>
      {icon}
    </button>
  );
};

export default IconButton;

import type { AppProps as NextAppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import ThemeProvider from '@app/contexts/theme-context';
import { DEFAULT_META_TITLE, DEFAULT_META_DESCRIPTION, ELEMENT_MODAL_ID } from '@app/constants/default';
import '@app/styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import NextNProgress from 'nextjs-progressbar';
import dynamic from 'next/dynamic';
import AppLayout from '@app/components/app-layout';
import PartnersPortal from '@app/components/partners-portal';
import ToastContainer from '@app/components/toast-container';

export const DyanicIsOffline = dynamic(
  () => import('@app/components/is-offline'),
  { ssr: false },
);

export interface AppProps extends NextAppProps {
  Component: NextAppProps['Component'] & { requireAuth: boolean }
}

// Use the <Provider> to improve performance and allow components that call
// `useSession()` anywhere in your application to access the `session` object.
const App = ({ Component, pageProps }: AppProps) => (
  <SessionProvider
    session={pageProps.session}
    // Re-fetch session every 5 minutes
    refetchInterval={5 * 60}
  >
    <ThemeProvider>
      <Head>
        <title>{DEFAULT_META_TITLE}</title>
        <meta name="description" content={DEFAULT_META_DESCRIPTION} />
        <meta
          name="viewport"
          // eslint-disable-next-line max-len
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=5,viewport-fit=cover"
        />
      </Head>
      <NextNProgress options={{ showSpinner: false }} />
      <AppLayout>
        <Component {...pageProps} />
      </AppLayout>
      <PartnersPortal />
      <ToastContainer />
      <DyanicIsOffline />
      <div id={ELEMENT_MODAL_ID.replace('#', '')} />
    </ThemeProvider>
  </SessionProvider>
);

export default App;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  ABOUT_PAGE,
  ACQUISITIONS_PAGE,
  CAREERS_PAGE,
  HAULERS_PAGE,
  HOME_PAGE,
  INDUSTRIES_PAGE,
  PARTNERS_PAGE,
  PLATFORM_PAGE,
} from "@app/constants/routes";
import { NAV_HEIGHT, Z_INDECES } from "@app/constants/default";
import Image from "next/image";
import Link from "next/link";
import Button from "../button";
import { useThemeContext } from "@app/contexts/theme-context";
import { useEffect } from "react";
import { useRouter } from "next/router";
import LoginDropdown from "./login-dropdown";
import IconButton from "../icon-button";
import Icon from "@mdi/react";
import { mdiMenu, mdiChevronRight } from "@mdi/js";
import ScheduleDemo from "../schedule-demo";

const Navbar = () => {
  const router = useRouter();
  const {
    isMobile,
    isTablet,
    isSidebarOpen,
    setIsSidebarOpen,
    showLoginDropdown,
    setShowLoginDropdown,
  } = useThemeContext();
  const isDesktop = !isMobile && !isTablet;

  useEffect(() => {
    if (!isDesktop) return;
    setIsSidebarOpen(false);
  }, [isDesktop]);
  const navRoutes = [
    {
      label: "Home",
      path: HOME_PAGE,
    },
    {
      label: "Platform",
      path: PLATFORM_PAGE,
    },
    {
      label: "Industries",
      path: INDUSTRIES_PAGE,
    },
    {
      label: "Haulers",
      path: HAULERS_PAGE,
    },
    {
      label: "Acquisitions",
      path: ACQUISITIONS_PAGE,
    },
    {
      label: "Partners",
      path: PARTNERS_PAGE,
    },
    {
      label: "About",
      path: ABOUT_PAGE,
    },
    {
      label: "Careers",
      path: CAREERS_PAGE,
    },
  ];

  const isActive = (path: string) => path === router.pathname;

  const logoNav = ({ src, width, height }) => {
    return (
      <Link href={HOME_PAGE} passHref>
        <span className="cursor-pointer">
          <Image alt="logo" src={src} height={height} width={width} />
        </span>
      </Link>
    );
  };

  const navRoutesComponent = navRoutes.map((route) => (
    <a key={route.path} href={route.path}>
      <div
        className={`p-2 xl:p-3 cursor-pointer btn-text-lg lg:btn-text-base
      hover:underline hover:underline-offset-12 hover:decoration-4 hover:decoration-secondary
      ${
        isActive(route.path)
          ? " underline underline-offset-12 decoration-4 decoration-secondary text-secondary"
          : "text-text-color-high-em"
      }`}
      >
        <span>{route.label}</span>
      </div>
    </a>
  ));

  const expandTransition = isSidebarOpen
    ? `w-60 transition-width duration-500 ease`
    : "w-0";
  const colorTransition =
    "transition-background-color duration-500 ease-linear";

  return (
    <>
      <div
        className="fixed top-0 left-0 right-0 bg-light"
        style={{ zIndex: Z_INDECES.NAVBAR }}
      >
        <div
          className="hidden xl:grid grid-cols-12 px-5 md:px-10 shadow-md w-full"
          style={{ height: NAV_HEIGHT }}
        >
          <div className="col-span-2 flex flex-row items-center">
            {logoNav({
              src: "/assets/svgs/global/logo-mark.svg",
              width: 178,
              height: 30,
            })}
          </div>
          <div className="col-span-10 flex flex-row items-center justify-end gap-x-6">
            <div className="flex flex-row">{navRoutesComponent}</div>
            <div className="flex flex-row gap-x-2">
              <div>
                <Button
                  buttonType="secondary"
                  size="base"
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowLoginDropdown((prev) => !prev)
                  }}
                >
                  Login
                </Button>
                {showLoginDropdown && <LoginDropdown />}
              </div>
              <ScheduleDemo>
                {(setIsModalOpen) => (
                  <Button size="base" full onClick={() => setIsModalOpen(true)}>
                    Schedule Demo
                  </Button>
                )}
              </ScheduleDemo>
            </div>
          </div>
        </div>
        <div
          className="grid xl:hidden grid-cols-12 px-5 md:px-10 shadow-md w-full"
          style={{ height: NAV_HEIGHT }}
        >
          <div className="col-span-6 flex flex-row items-center justify-start">
            {logoNav({
              src: "/assets/svgs/global/logo.svg",
              width: 40,
              height: 40,
            })}
          </div>
          <div className="col-span-6 flex flex-row items-center justify-end">
            <IconButton
              buttonType="secondary"
              onClick={(e) => {
                e.stopPropagation();
                setIsSidebarOpen(true);
              }}
              icon={<Icon size={1} path={mdiMenu} />}
            />
          </div>
        </div>
      </div>
      <aside
        className={`
          bg-background-mid-em 
          ${colorTransition} xl:hidden fixed top-0 right-0 bottom-0 
          ${expandTransition}
          h-full
        `}
        style={{ zIndex: Z_INDECES.NAVBAR }}
      >
        {isSidebarOpen && (
          <div className={`px-6 pb-6 pt-12 shadow-lg relative h-full`}>
            <div className="absolute -left-4 top-4">
              <IconButton
                buttonType="primary"
                size="sm"
                onClick={() => setIsSidebarOpen(false)}
                icon={<Icon size={1} path={mdiChevronRight} />}
              />
            </div>
            <div className="flex flex-col justify-between h-full">
              <div className="grid gap-y-3">{navRoutesComponent}</div>
              <div className="grid gap-y-3">
                <Button
                  buttonType="secondary"
                  size="base"
                  full
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowLoginDropdown((prev) => !prev);
                  }}
                >
                  Login
                </Button>
                {showLoginDropdown && (
                  <LoginDropdown />
                )}
                <ScheduleDemo>
                  {(setIsModalOpen) => (
                    <Button
                      size="base"
                      full
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen(true);
                      }}
                    >
                      Schedule Demo
                    </Button>
                  )}
                </ScheduleDemo>
              </div>
            </div>
          </div>
        )}
      </aside>
    </>
  );
};
export default Navbar;

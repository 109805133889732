const colors = require('tailwindcss/colors');
const tailwindForms = require('@tailwindcss/forms');
const plugin = require('tailwindcss/plugin');
const defaultTheme = require('tailwindcss/defaultTheme');

const assignColor = (light, DEFAULT, dark, lowEm) => ({
  light: light || DEFAULT,
  DEFAULT,
  dark,
  'low-em': lowEm || null,
});

const assignStyle = (fontSize, fontWeight, lineHeight, letterSpacing) => ({
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
});

module.exports = {
  mode: 'jit',
  purge: {
    content: [
      './pages/**/*.{js,ts,jsx,tsx}',
      './components/**/*.{js,ts,jsx,tsx}',
      './forms/**/*.{js,ts,jsx,tsx}',
    ],
    options: {
      keyframes: true,
    },
  },
  darkMode: 'class', // or 'media' or 'class'
  theme: {
    extend: {
      boxShadow: {
        'xs-light': '0px 1px 2px rgba(16, 24, 40, 0.05)',
        'sm-light': '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        'md-light': '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
        'lg-light': '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
        'xl-light': '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
        '2xl-light': '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
        '3xl-light': '0px 32px 64px rgba(16, 24, 40, 0.14)',
        'xs-dark': '0px 1px 2px rgba(0, 0, 0, 0.5)',
        'sm-dark': '0px 1px 4px rgba(0, 0, 0, 0.6)',
        'md-dark': '0px 4px 8px -2px rgba(0, 0, 0, 0.75)',
        'lg-dark': '0px 12px 16px -4px rgba(0, 0, 0, 0.55)',
        'xl-dark': '0px 20px 24px -4px rgba(0, 0, 0, 0.45)',
        '2xl-dark': '0px 24px 48px -12px rgba(0, 0, 0, 0.82)',
        '3xl-dark': '0px 32px 64px -12px rgba(0, 0, 0, 0.86)',
        'outer-secondary': '0px 0px 60px 0px rgba(34, 197, 94, 0.6)',
      },
      textUnderlineOffset: {
        12: '12px',
      },
    },
    screens: {
      xxxs: '280px',
      '2xs': '320px',
      xxs: '360px',
      xs: '375px',
      'sm-2': '428px',
      sm: defaultTheme.screens.sm,
      'sm-3': '744px',
      md: defaultTheme.screens.md,
      lg: defaultTheme.screens.lg,
      xl: defaultTheme.screens.xl,
      'xl-2': '1440px',
      '2xl': defaultTheme.screens['2xl'],
      '3xl': '1680px',
      '4xl': '1920px',
      '5xl': '2560px',
    },
    colors: {
      black: colors.black,
      indigo: colors.indigo,
      orange: colors.orange,
      white: colors.white,
      gray: colors.coolGray,
      red: colors.red,
      green: colors.green,
      blue: colors.blue,
      transparent: 'transparent',
      current: 'currentColor',
      info: '#11324D',
      darker: '#132631',
      light: '#FFFFFF',
      primary: assignColor('#06B6D4', '#0891B2', '#0E7490', '#ECFEFF'),
      secondary: assignColor('#22C55E', '#16A34A', '#158040', '#F0FDF4'),
      base: assignColor('#f3f6f9', '#fff', '#0C4A6E'),
      success: assignColor('#F7FEE7', '#A3E635', '#65A30D'),
      warning: assignColor('#FEF9C3', '#FDE047', '#EAB308'),
      error: assignColor('#FEE2E2', '#EF4444', '#B91C1C'),
      'text-color': {
        'high-em': assignColor(null, '#334155', '#FFFFFF'),
        'mid-em': assignColor(null, '#64748b', '#FFFFFF'),
        'low-em': assignColor(null, '#94a3b8', '#FFFFFF'),
        disabled: assignColor(null, '#cbd5e1', '#FFFFFF'),
        light: '#FFFFFF',
      },
      background: {
        'primary-dark': assignColor(null, '#0C4A6E', '#132631'),
        main: assignColor(null, '#FFFFFF', '#111827'),
        'mid-em': assignColor(null, '#f3f4f6', '#65A30D'),
        'low-em': assignColor(null, '#f9fafb', '#1F2937'),
        stroke: assignColor(null, '#e5e7eb', '#4B5563'),
        disabled: assignColor(null, '#f3f4f6', '#374151'),
      },
    },
  },
  variants: {
    extend: {
      backgroundColor: ['odd', 'even'],
      opacity: ['disabled'],
      cursor: ['disabled'],
      divideColor: ['dark'],
    },
  },
  plugins: [
    tailwindForms,
    plugin(({ addUtilities, addBase }) => {
      addUtilities({
        '.text-body-2xl': assignStyle('24px', '500', '40px', '0px'),
        '.text-body-xl-bold': assignStyle('18px', '700', '24px', '0px'),
        '.text-body-xl-medium': assignStyle('18px', '500', '24px', '0px'),
        '.text-body-xl': assignStyle('18px', 'normal', '24px', '0px'),
        '.text-body-lg-bold': assignStyle('16px', '700', '24px', '0px'),
        '.text-body-lg': assignStyle('16px', 'normal', '24px', '0px'),
        '.text-body-md-bold': assignStyle('14px', '700', '20px', '0px'),
        '.text-body-md': assignStyle('14px', 'normal', '20px', '0px'),
        '.text-body-sm': assignStyle('12px', 'normal', '16px', '0.25px'),
        '.label-xl': assignStyle('20px', '600', '30px', '0px'),
        '.label-lg': assignStyle('16px', '600', '24px', '0px'),
        '.label-md': assignStyle('14px', '500', '20px', '0px'),
        '.label-sm': assignStyle('12px', '500', '16px', '0px'),
        '.label-sm-bold': assignStyle('12px', '700', '16px', '0px'),
        '.label-lg-bold': assignStyle('16px', '700', '24px', '0px'),
        '.btn-text-lg': assignStyle('16px', '500', '24px', '0px'),
        '.btn-text-base': assignStyle('14px', '500', '20px', '0px'),
        '.btn-text-sm': assignStyle('14px', '500', '16px', '0px'),
        '.btn-text-xs': assignStyle('12px', '500', '16px', '0px'),
        '.caption': assignStyle('11px', '500', '20px', '0.4px'),
        '.h1': assignStyle('70px', '700', '72px', '0px'),
        '.mobile-h1': assignStyle('40px', '700', '54px', '0px'),
        '.h2': assignStyle('60px', '700', '64px', '0px'),
        '.mobile-h2': assignStyle('30px', '700', '40px', '0px'),
        '.h3': assignStyle('40px', '700', '54px', '0px'),
        '.mobile-h3': assignStyle('24px', '700', '32px', '0px'),
        '.h4': assignStyle('30px', '700', '40px', '0px'),
        '.mobile-h4': assignStyle('18px', '700', '30px', '0px'),
        '.h5': assignStyle('24px', '700', '32px', '0px'),
        '.subheading': assignStyle('18px', '500', '28px', '0px'),
        '.h6': assignStyle('18px', '700', '28px', '0px'),
        '.subheading-xxxxl': assignStyle('56px', '500', '40px', '0px'),
        '.subheading-xxxxl-2': assignStyle('56px', '500', '64px', '0px'),
        '.subheading-xxxxl-3': assignStyle('56px', '700', '64px', '0px'),
        '.subheading-xxxxl-4': assignStyle('56px', '800', '64px', '0px'),
        '.subheading-xxxl': assignStyle('48px', '500', '40px', '0px'),
        '.subheading-xxxl-2': assignStyle('48px', '500', '64px', '0px'),
        '.subheading-xxxl-3': assignStyle('48px', '700', '64px', '0px'),
        '.subheading-xxxl-4': assignStyle('48px', '800', '64px', '0px'),
        '.subheading-xxl': assignStyle('40px', '500', '40px', '0px'),
        '.subheading-xxl-2': assignStyle('40px', '500', '64px', '0px'),
        '.subheading-xxl-3': assignStyle('40px', '700', '64px', '0px'),
        '.subheading-xxl-4': assignStyle('40px', '800', '64px', '0px'),
        '.subheading-xl': assignStyle('32px', '500', '40px', '0px'),
        '.subheading-xl-2': assignStyle('32px', '500', '64px', '0px'),
        '.subheading-xl-3': assignStyle('32px', '700', '64px', '0px'),
        '.subheading-xl-4': assignStyle('32px', '800', '64px', '0px'),
        '.no-scrollbar': { '-ms-overflow-style': 'none', 'scroll-bar-width': 'none' },
        '.blur-content': { '-webkit-backdrop-filter': 'blur(5px)', 'backdrop-filter': 'blur(5px)' },
      });
      addBase({
        h1: assignStyle('40px', '700', '54px', '0px'),
        h2: assignStyle('30px', '700', '40px', '0px'),
        h3: assignStyle('24px', '700', '32px', '0px'),
        h4: assignStyle('18px', '700', '30px', '0px'),
        h5: assignStyle('18px', '500', '28px', '0px'),
        h6: assignStyle('18px', '400', '28px', '0px'),
      });
    }),
  ],
};
